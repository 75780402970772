import { AxiosError, AxiosResponse } from "axios"

import api from "../../services/api.service"
import { IResponseCommon } from "../common.type"
import { IListenerFormSendedData, IResponseListenerCheckField, IResponseListenerFiles, IResponseListeners, IResponseListenersCard } from "./listeners.types"


const route = '/listeners'

/** Проверка по номеру телефона, существование слушателя */
export const getListenerCheckPhone = async (listenerID: number, clientPhone: string) => {
    let response: IResponseListenerCheckField

    try {
        const res: AxiosResponse<IResponseListenerCheckField, AxiosError> = await api.get(`${route}/getListenerCheckPhone`, {params: {listenerID, clientPhone}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseListenerCheckField
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Проверка по СНИЛС, существование слушателя */
export const getListenerCheckSnils = async (listenerID: number, snils: string) => {
    let response: IResponseListenerCheckField

    try {
        const res: AxiosResponse<IResponseListenerCheckField, AxiosError> = await api.get(`${route}/getListenerCheckSnils`, {params: {listenerID, snils}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseListenerCheckField
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}



/** Получение списка слушателей */
export const getListeners = async (pageNumber: number, search?: string) => {
    let response: IResponseListeners

    try {
        const res: AxiosResponse<IResponseListeners, AxiosError> = await api.get(`${route}/getListeners`, {params: {pageNumber, search}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500, 
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseListeners
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Получение списка файлов слушателя */
export const getListenerFiles = async (listenerID: number) => {
    let response: IResponseListenerFiles

    try {
        const res: AxiosResponse<IResponseListenerFiles, AxiosError> = await api.get(`${route}/getListenerFiles?listenerID=${listenerID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseListenerFiles
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}



/** Получение данных по анкете слушателя */
export const getListenerCard = async (listenerID: number) => {
    let response: IResponseListenersCard

    try {
        const res: AxiosResponse<IResponseListenersCard, AxiosError> = await api.get(`${route}/getListenerCard`, {params: {listenerID}})

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseListenersCard
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Сохранение данных по анкете слушателя */
export const setListenerCardSave = async (listenerID: number, data: IListenerFormSendedData, files?: File[]) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.put(
            `${route}/setListenerCardSave?listenerID=${listenerID}`, 
            { listenerData: JSON.stringify(data), docs: files }, 
            { headers: { "Content-Type": `multipart/form-data` } }
        )

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен. (ПОЗОВИ МЕНЯ, МНЕ НАДО ГЛЯНУТЬ СВОИМИ ГЛАЗАМИ!!!!)',
                errors: null
            }

            console.log(res)
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {
            response = { 
                status: 500, message: 'Сервер не доступен. Сообщи мне, код 2', errors: (error as AxiosError).code
            }
            
            return response 
        }

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = { status: 500, message: resError?.message, errors: resError.errors }
        return response
    }
}



/** Удаление файлов слушателя */
export const setListenerDeleteFiles = async (listenerID: number, fileID: number) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.delete(`${route}/setListenerDeleteFiles?listenerID=${listenerID}&fileID=${fileID}`)

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}
/** Загрузка файлов слушателя */
export const setListenerUploadFiles = async (listenerID: number, files: File[]) => {
    let response: IResponseCommon

    try {
        const res: AxiosResponse<IResponseCommon, AxiosError> = await api.post(
            `${route}/setListenerUploadFiles?listenerID=${listenerID}`, { docs: files }, { headers: { "Content-Type": `multipart/form-data` } }
        )

        if (res.statusText !== 'ERR_NETWORK') {
            response = res.data
        } else {
            response = {
                status: 500,
                message: 'Сервер временно не доступен.',
                errors: null
            }
        }

        return response
    } catch(error) {
        if ((error as AxiosError).code === 'ERR_NETWORK') {response = {status: 500, message: 'Сервер не доступен', errors: (error as AxiosError).code}; return response}

        const resError = (error as AxiosError).response?.data as IResponseCommon
        response = {status: 500, message: resError?.message, errors: resError.errors}
        return response
    }
}

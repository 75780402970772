import axios, { AxiosError } from 'axios'

// export const APP_API_URL='http://192.168.1.105:5006/api'
// export const APP_WEB_SOCKET='ws://192.168.1.105:5006/api'

// export const APP_API_URL='http://192.168.0.167:5006/api'
// export const APP_WEB_SOCKET='ws://192.168.0.167:5006/api'

export const APP_API_URL='https://crm.iktbox.ru/crm'
export const APP_WEB_SOCKET='wss://crm.iktbox.ru/ws'


axios.defaults.withCredentials = true
const api = axios.create({
    baseURL: APP_API_URL,
    withCredentials: true
})

api.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('us_id')}`

    return config
}, (error) => {
    Promise.reject(error)
})

api.interceptors.response.use(
    async (config) => {
        return config
    },
    async (error) => {
        const originalRequest = error.config

        // Проверяем, была ли это ошибка сети
        if ((error as AxiosError).code === 'ERR_NETWORK' || (error as AxiosError).code === 'ERR_BAD_RESPONSE') {
            // Проверяем, существует ли у запроса счётчик попыток
            originalRequest._retryCount = originalRequest._retryCount || 0

            // Если счётчик попыток меньше 4, пробуем ещё раз
            if (originalRequest._retryCount < 4) {
                originalRequest._retryCount++

                // Задержка перед новой попыткой (например, 500 мс)
                await new Promise((resolve) => setTimeout(resolve, 500))
                return api.request(originalRequest)
            }

            // Если превышен лимит попыток, возвращаем ошибку
            return Promise.reject({ statusText: error.code })
        }

        // Если ошибка не связана с сетью
        if (error.response?.status === 401) {
            try {
                const response = await axios.get(`${APP_API_URL}/auth/refresh`)
                if (response.data.token && response.data.tokenExpiration) {
                    localStorage.setItem('us_id', response.data.token)
                    localStorage.setItem('us_exp', response.data.tokenExpiration.toString())
                } else {
                    localStorage.removeItem('us_id')
                    localStorage.removeItem('us_exp')
                }

                return api.request(originalRequest)
            } catch (e) {
                localStorage.removeItem('us_id')
                localStorage.removeItem('us_exp')
            }
        }

        // Возвращаем ответ для остальных ошибок
        return Promise.reject(error.response || { statusText: error.code })
    }
)

export default api